exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-jaccount-tsx": () => import("./../../../src/pages/auth/jaccount.tsx" /* webpackChunkName: "component---src-pages-auth-jaccount-tsx" */),
  "component---src-pages-category-[id]-tsx": () => import("./../../../src/pages/category/[id].tsx" /* webpackChunkName: "component---src-pages-category-[id]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tag-[id]-tsx": () => import("./../../../src/pages/tag/[id].tsx" /* webpackChunkName: "component---src-pages-tag-[id]-tsx" */),
  "component---src-pages-thread-[id]-tsx": () => import("./../../../src/pages/thread/[id].tsx" /* webpackChunkName: "component---src-pages-thread-[id]-tsx" */),
  "component---src-pages-trend-tsx": () => import("./../../../src/pages/trend.tsx" /* webpackChunkName: "component---src-pages-trend-tsx" */)
}

