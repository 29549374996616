// source: thread.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var model_pb = require('./model_pb.js');
goog.object.extend(proto, model_pb);
var category_pb = require('./category_pb.js');
goog.object.extend(proto, category_pb);
var tag_pb = require('./tag_pb.js');
goog.object.extend(proto, tag_pb);
var identity_pb = require('./identity_pb.js');
goog.object.extend(proto, identity_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.model.Thread', null, global);
goog.exportSymbol('proto.model.ThreadStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Thread = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.Thread.repeatedFields_, null);
};
goog.inherits(proto.model.Thread, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Thread.displayName = 'proto.model.Thread';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.Thread.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Thread.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Thread.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Thread} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Thread.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && model_pb.Model.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    category: (f = msg.getCategory()) && category_pb.Category.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    tag_pb.Tag.toObject, includeInstance),
    identityCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    content: jspb.Message.getFieldWithDefault(msg, 7, ""),
    viewCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    likeCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    hateCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    replyCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    isTop: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isFav: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isLike: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isHate: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    status: jspb.Message.getFieldWithDefault(msg, 16, 0),
    lastReplyAt: jspb.Message.getFieldWithDefault(msg, 17, "0"),
    identity: (f = msg.getIdentity()) && identity_pb.Identity.toObject(includeInstance, f),
    isSage: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    isReadOnly: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    hideInTimeline: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    isAlice: (f = msg.getIsAlice()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    lastRead: (f = msg.getLastRead()) && google_protobuf_wrappers_pb.UInt64Value.toObject(includeInstance, f),
    hasRead: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    appreciationCount: jspb.Message.getFieldWithDefault(msg, 25, 0),
    isAppreciated: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    canDelete: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    hasPopular: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    remark: jspb.Message.getFieldWithDefault(msg, 29, ""),
    preview: jspb.Message.getFieldWithDefault(msg, 30, ""),
    disableHate: (f = msg.getDisableHate()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hideReason: jspb.Message.getFieldWithDefault(msg, 32, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Thread}
 */
proto.model.Thread.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Thread;
  return proto.model.Thread.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Thread} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Thread}
 */
proto.model.Thread.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_pb.Model;
      reader.readMessage(value,model_pb.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = new category_pb.Category;
      reader.readMessage(value,category_pb.Category.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 5:
      var value = new tag_pb.Tag;
      reader.readMessage(value,tag_pb.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentityCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setViewCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLikeCount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHateCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReplyCount(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTop(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFav(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLike(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHate(value);
      break;
    case 16:
      var value = /** @type {!proto.model.ThreadStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLastReplyAt(value);
      break;
    case 18:
      var value = new identity_pb.Identity;
      reader.readMessage(value,identity_pb.Identity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSage(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReadOnly(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideInTimeline(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsAlice(value);
      break;
    case 23:
      var value = new google_protobuf_wrappers_pb.UInt64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt64Value.deserializeBinaryFromReader);
      msg.setLastRead(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRead(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAppreciationCount(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAppreciated(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDelete(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPopular(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreview(value);
      break;
    case 31:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDisableHate(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setHideReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Thread.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Thread.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Thread} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Thread.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      model_pb.Model.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      category_pb.Category.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      tag_pb.Tag.serializeBinaryToWriter
    );
  }
  f = message.getIdentityCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getViewCount();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getLikeCount();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getHateCount();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getReplyCount();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getIsTop();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsFav();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsLike();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsHate();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getLastReplyAt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      17,
      f
    );
  }
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      identity_pb.Identity.serializeBinaryToWriter
    );
  }
  f = message.getIsSage();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getIsReadOnly();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getHideInTimeline();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getIsAlice();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLastRead();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_wrappers_pb.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getHasRead();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getAppreciationCount();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getIsAppreciated();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getCanDelete();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getHasPopular();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getPreview();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getDisableHate();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHideReason();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
};


/**
 * optional Model model = 1;
 * @return {?proto.model.Model}
 */
proto.model.Thread.prototype.getModel = function() {
  return /** @type{?proto.model.Model} */ (
    jspb.Message.getWrapperField(this, model_pb.Model, 1));
};


/**
 * @param {?proto.model.Model|undefined} value
 * @return {!proto.model.Thread} returns this
*/
proto.model.Thread.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Thread.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.model.Thread.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 category_id = 3;
 * @return {number}
 */
proto.model.Thread.prototype.getCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Category category = 4;
 * @return {?proto.model.Category}
 */
proto.model.Thread.prototype.getCategory = function() {
  return /** @type{?proto.model.Category} */ (
    jspb.Message.getWrapperField(this, category_pb.Category, 4));
};


/**
 * @param {?proto.model.Category|undefined} value
 * @return {!proto.model.Thread} returns this
*/
proto.model.Thread.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Thread.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Tag tags = 5;
 * @return {!Array<!proto.model.Tag>}
 */
proto.model.Thread.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.model.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, tag_pb.Tag, 5));
};


/**
 * @param {!Array<!proto.model.Tag>} value
 * @return {!proto.model.Thread} returns this
*/
proto.model.Thread.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.model.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Tag}
 */
proto.model.Thread.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.model.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string identity_code = 6;
 * @return {string}
 */
proto.model.Thread.prototype.getIdentityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setIdentityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string content = 7;
 * @return {string}
 */
proto.model.Thread.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint64 view_count = 8;
 * @return {number}
 */
proto.model.Thread.prototype.getViewCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setViewCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 like_count = 9;
 * @return {number}
 */
proto.model.Thread.prototype.getLikeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setLikeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 hate_count = 10;
 * @return {number}
 */
proto.model.Thread.prototype.getHateCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setHateCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 reply_count = 11;
 * @return {number}
 */
proto.model.Thread.prototype.getReplyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setReplyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool is_top = 12;
 * @return {boolean}
 */
proto.model.Thread.prototype.getIsTop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setIsTop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_fav = 13;
 * @return {boolean}
 */
proto.model.Thread.prototype.getIsFav = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setIsFav = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_like = 14;
 * @return {boolean}
 */
proto.model.Thread.prototype.getIsLike = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setIsLike = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_hate = 15;
 * @return {boolean}
 */
proto.model.Thread.prototype.getIsHate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setIsHate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional ThreadStatus status = 16;
 * @return {!proto.model.ThreadStatus}
 */
proto.model.Thread.prototype.getStatus = function() {
  return /** @type {!proto.model.ThreadStatus} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.model.ThreadStatus} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional int64 last_reply_at = 17;
 * @return {string}
 */
proto.model.Thread.prototype.getLastReplyAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, "0"));
};


/**
 * @param {string} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setLastReplyAt = function(value) {
  return jspb.Message.setProto3StringIntField(this, 17, value);
};


/**
 * optional Identity identity = 18;
 * @return {?proto.model.Identity}
 */
proto.model.Thread.prototype.getIdentity = function() {
  return /** @type{?proto.model.Identity} */ (
    jspb.Message.getWrapperField(this, identity_pb.Identity, 18));
};


/**
 * @param {?proto.model.Identity|undefined} value
 * @return {!proto.model.Thread} returns this
*/
proto.model.Thread.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Thread.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool is_sage = 19;
 * @return {boolean}
 */
proto.model.Thread.prototype.getIsSage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setIsSage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool is_read_only = 20;
 * @return {boolean}
 */
proto.model.Thread.prototype.getIsReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setIsReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool hide_in_timeline = 21;
 * @return {boolean}
 */
proto.model.Thread.prototype.getHideInTimeline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setHideInTimeline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional google.protobuf.BoolValue is_alice = 22;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.Thread.prototype.getIsAlice = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 22));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.Thread} returns this
*/
proto.model.Thread.prototype.setIsAlice = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.clearIsAlice = function() {
  return this.setIsAlice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Thread.prototype.hasIsAlice = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.UInt64Value last_read = 23;
 * @return {?proto.google.protobuf.UInt64Value}
 */
proto.model.Thread.prototype.getLastRead = function() {
  return /** @type{?proto.google.protobuf.UInt64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt64Value, 23));
};


/**
 * @param {?proto.google.protobuf.UInt64Value|undefined} value
 * @return {!proto.model.Thread} returns this
*/
proto.model.Thread.prototype.setLastRead = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.clearLastRead = function() {
  return this.setLastRead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Thread.prototype.hasLastRead = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional bool has_read = 24;
 * @return {boolean}
 */
proto.model.Thread.prototype.getHasRead = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setHasRead = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional uint64 appreciation_count = 25;
 * @return {number}
 */
proto.model.Thread.prototype.getAppreciationCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setAppreciationCount = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool is_appreciated = 26;
 * @return {boolean}
 */
proto.model.Thread.prototype.getIsAppreciated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setIsAppreciated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool can_delete = 27;
 * @return {boolean}
 */
proto.model.Thread.prototype.getCanDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setCanDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool has_popular = 28;
 * @return {boolean}
 */
proto.model.Thread.prototype.getHasPopular = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setHasPopular = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional string remark = 29;
 * @return {string}
 */
proto.model.Thread.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string preview = 30;
 * @return {string}
 */
proto.model.Thread.prototype.getPreview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setPreview = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional google.protobuf.BoolValue disable_hate = 31;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.Thread.prototype.getDisableHate = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 31));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.Thread} returns this
*/
proto.model.Thread.prototype.setDisableHate = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.clearDisableHate = function() {
  return this.setDisableHate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Thread.prototype.hasDisableHate = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string hide_reason = 32;
 * @return {string}
 */
proto.model.Thread.prototype.getHideReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Thread} returns this
 */
proto.model.Thread.prototype.setHideReason = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * @enum {number}
 */
proto.model.ThreadStatus = {
  THREADSTATUSNORMAL: 0,
  THREADSTATUSHIDE: 1,
  THREADSTATUSCOLLAPSED: 2
};

goog.object.extend(exports, proto.model);
