// source: setting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var model_pb = require('./model_pb.js');
goog.object.extend(proto, model_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.model.Setting', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Setting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.Setting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Setting.displayName = 'proto.model.Setting';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Setting.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Setting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Setting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Setting.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && model_pb.Model.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filteredWords: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filteredTagIds: jspb.Message.getFieldWithDefault(msg, 4, ""),
    inactiveRead: (f = msg.getInactiveRead()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hideBadPosts: (f = msg.getHideBadPosts()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hideBadThreads: (f = msg.getHideBadThreads()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    enablePushNotifications: (f = msg.getEnablePushNotifications()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    enableUtilities: (f = msg.getEnableUtilities()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    filteredCategoryIds: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Setting}
 */
proto.model.Setting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Setting;
  return proto.model.Setting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Setting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Setting}
 */
proto.model.Setting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_pb.Model;
      reader.readMessage(value,model_pb.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilteredWords(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilteredTagIds(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setInactiveRead(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHideBadPosts(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHideBadThreads(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setEnablePushNotifications(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setEnableUtilities(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilteredCategoryIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Setting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Setting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Setting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Setting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      model_pb.Model.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFilteredWords();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilteredTagIds();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInactiveRead();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHideBadPosts();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHideBadThreads();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnablePushNotifications();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEnableUtilities();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getFilteredCategoryIds();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional Model model = 1;
 * @return {?proto.model.Model}
 */
proto.model.Setting.prototype.getModel = function() {
  return /** @type{?proto.model.Model} */ (
    jspb.Message.getWrapperField(this, model_pb.Model, 1));
};


/**
 * @param {?proto.model.Model|undefined} value
 * @return {!proto.model.Setting} returns this
*/
proto.model.Setting.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Setting.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 user_id = 2;
 * @return {number}
 */
proto.model.Setting.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string filtered_words = 3;
 * @return {string}
 */
proto.model.Setting.prototype.getFilteredWords = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.setFilteredWords = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string filtered_tag_ids = 4;
 * @return {string}
 */
proto.model.Setting.prototype.getFilteredTagIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.setFilteredTagIds = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.BoolValue inactive_read = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.Setting.prototype.getInactiveRead = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.Setting} returns this
*/
proto.model.Setting.prototype.setInactiveRead = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.clearInactiveRead = function() {
  return this.setInactiveRead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Setting.prototype.hasInactiveRead = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue hide_bad_posts = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.Setting.prototype.getHideBadPosts = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.Setting} returns this
*/
proto.model.Setting.prototype.setHideBadPosts = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.clearHideBadPosts = function() {
  return this.setHideBadPosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Setting.prototype.hasHideBadPosts = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue hide_bad_threads = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.Setting.prototype.getHideBadThreads = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.Setting} returns this
*/
proto.model.Setting.prototype.setHideBadThreads = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.clearHideBadThreads = function() {
  return this.setHideBadThreads(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Setting.prototype.hasHideBadThreads = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue enable_push_notifications = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.Setting.prototype.getEnablePushNotifications = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.Setting} returns this
*/
proto.model.Setting.prototype.setEnablePushNotifications = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.clearEnablePushNotifications = function() {
  return this.setEnablePushNotifications(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Setting.prototype.hasEnablePushNotifications = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue enable_utilities = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.Setting.prototype.getEnableUtilities = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.Setting} returns this
*/
proto.model.Setting.prototype.setEnableUtilities = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.clearEnableUtilities = function() {
  return this.setEnableUtilities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Setting.prototype.hasEnableUtilities = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string filtered_category_ids = 10;
 * @return {string}
 */
proto.model.Setting.prototype.getFilteredCategoryIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Setting} returns this
 */
proto.model.Setting.prototype.setFilteredCategoryIds = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


goog.object.extend(exports, proto.model);
