// source: user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var model_pb = require('./model_pb.js');
goog.object.extend(proto, model_pb);
var identity_pb = require('./identity_pb.js');
goog.object.extend(proto, identity_pb);
var setting_pb = require('./setting_pb.js');
goog.object.extend(proto, setting_pb);
var user_fish_pb = require('./user_fish_pb.js');
goog.object.extend(proto, user_fish_pb);
goog.exportSymbol('proto.model.User', null, global);
goog.exportSymbol('proto.model.UserRole', null, global);
goog.exportSymbol('proto.model.UserStat', null, global);
goog.exportSymbol('proto.model.UserStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.User.repeatedFields_, null);
};
goog.inherits(proto.model.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.User.displayName = 'proto.model.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.UserStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.UserStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.UserStat.displayName = 'proto.model.UserStat';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.User.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.User.prototype.toObject = function(opt_includeInstance) {
  return proto.model.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && model_pb.Model.toObject(includeInstance, f),
    account: jspb.Message.getFieldWithDefault(msg, 2, ""),
    identitiesList: jspb.Message.toObjectList(msg.getIdentitiesList(),
    identity_pb.Identity.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    role: jspb.Message.getFieldWithDefault(msg, 5, 0),
    setting: (f = msg.getSetting()) && setting_pb.Setting.toObject(includeInstance, f),
    fish: (f = msg.getFish()) && user_fish_pb.UserFish.toObject(includeInstance, f),
    stat: (f = msg.getStat()) && proto.model.UserStat.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.User}
 */
proto.model.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.User;
  return proto.model.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.User}
 */
proto.model.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_pb.Model;
      reader.readMessage(value,model_pb.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = new identity_pb.Identity;
      reader.readMessage(value,identity_pb.Identity.deserializeBinaryFromReader);
      msg.addIdentities(value);
      break;
    case 4:
      var value = /** @type {!proto.model.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.model.UserRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 6:
      var value = new setting_pb.Setting;
      reader.readMessage(value,setting_pb.Setting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    case 7:
      var value = new user_fish_pb.UserFish;
      reader.readMessage(value,user_fish_pb.UserFish.deserializeBinaryFromReader);
      msg.setFish(value);
      break;
    case 8:
      var value = new proto.model.UserStat;
      reader.readMessage(value,proto.model.UserStat.deserializeBinaryFromReader);
      msg.setStat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      model_pb.Model.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdentitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      identity_pb.Identity.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      setting_pb.Setting.serializeBinaryToWriter
    );
  }
  f = message.getFish();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      user_fish_pb.UserFish.serializeBinaryToWriter
    );
  }
  f = message.getStat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.model.UserStat.serializeBinaryToWriter
    );
  }
};


/**
 * optional Model model = 1;
 * @return {?proto.model.Model}
 */
proto.model.User.prototype.getModel = function() {
  return /** @type{?proto.model.Model} */ (
    jspb.Message.getWrapperField(this, model_pb.Model, 1));
};


/**
 * @param {?proto.model.Model|undefined} value
 * @return {!proto.model.User} returns this
*/
proto.model.User.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.User} returns this
 */
proto.model.User.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.User.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.model.User.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.User} returns this
 */
proto.model.User.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Identity identities = 3;
 * @return {!Array<!proto.model.Identity>}
 */
proto.model.User.prototype.getIdentitiesList = function() {
  return /** @type{!Array<!proto.model.Identity>} */ (
    jspb.Message.getRepeatedWrapperField(this, identity_pb.Identity, 3));
};


/**
 * @param {!Array<!proto.model.Identity>} value
 * @return {!proto.model.User} returns this
*/
proto.model.User.prototype.setIdentitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.model.Identity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Identity}
 */
proto.model.User.prototype.addIdentities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.model.Identity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.User} returns this
 */
proto.model.User.prototype.clearIdentitiesList = function() {
  return this.setIdentitiesList([]);
};


/**
 * optional UserStatus status = 4;
 * @return {!proto.model.UserStatus}
 */
proto.model.User.prototype.getStatus = function() {
  return /** @type {!proto.model.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.model.UserStatus} value
 * @return {!proto.model.User} returns this
 */
proto.model.User.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional UserRole role = 5;
 * @return {!proto.model.UserRole}
 */
proto.model.User.prototype.getRole = function() {
  return /** @type {!proto.model.UserRole} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.model.UserRole} value
 * @return {!proto.model.User} returns this
 */
proto.model.User.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Setting setting = 6;
 * @return {?proto.model.Setting}
 */
proto.model.User.prototype.getSetting = function() {
  return /** @type{?proto.model.Setting} */ (
    jspb.Message.getWrapperField(this, setting_pb.Setting, 6));
};


/**
 * @param {?proto.model.Setting|undefined} value
 * @return {!proto.model.User} returns this
*/
proto.model.User.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.User} returns this
 */
proto.model.User.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.User.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UserFish fish = 7;
 * @return {?proto.model.UserFish}
 */
proto.model.User.prototype.getFish = function() {
  return /** @type{?proto.model.UserFish} */ (
    jspb.Message.getWrapperField(this, user_fish_pb.UserFish, 7));
};


/**
 * @param {?proto.model.UserFish|undefined} value
 * @return {!proto.model.User} returns this
*/
proto.model.User.prototype.setFish = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.User} returns this
 */
proto.model.User.prototype.clearFish = function() {
  return this.setFish(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.User.prototype.hasFish = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional UserStat stat = 8;
 * @return {?proto.model.UserStat}
 */
proto.model.User.prototype.getStat = function() {
  return /** @type{?proto.model.UserStat} */ (
    jspb.Message.getWrapperField(this, proto.model.UserStat, 8));
};


/**
 * @param {?proto.model.UserStat|undefined} value
 * @return {!proto.model.User} returns this
*/
proto.model.User.prototype.setStat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.User} returns this
 */
proto.model.User.prototype.clearStat = function() {
  return this.setStat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.User.prototype.hasStat = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.UserStat.prototype.toObject = function(opt_includeInstance) {
  return proto.model.UserStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.UserStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.UserStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    likedCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    threadCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    appreciationCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.UserStat}
 */
proto.model.UserStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.UserStat;
  return proto.model.UserStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.UserStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.UserStat}
 */
proto.model.UserStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLikedCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setThreadCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAppreciationCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.UserStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.UserStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.UserStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.UserStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLikedCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getThreadCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAppreciationCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional uint64 user_id = 1;
 * @return {number}
 */
proto.model.UserStat.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.UserStat} returns this
 */
proto.model.UserStat.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 liked_count = 2;
 * @return {number}
 */
proto.model.UserStat.prototype.getLikedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.UserStat} returns this
 */
proto.model.UserStat.prototype.setLikedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 thread_count = 3;
 * @return {number}
 */
proto.model.UserStat.prototype.getThreadCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.UserStat} returns this
 */
proto.model.UserStat.prototype.setThreadCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 appreciation_count = 4;
 * @return {number}
 */
proto.model.UserStat.prototype.getAppreciationCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.UserStat} returns this
 */
proto.model.UserStat.prototype.setAppreciationCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.model.UserStatus = {
  USERSTATUSNORMAL: 0,
  USERSTATUSREADONLY: 1,
  USERSTATUSBANNED: 2
};

/**
 * @enum {number}
 */
proto.model.UserRole = {
  USERROLENORMAL: 0,
  USERROLEADMIN: 1,
  USERROLESUPERADMIN: 2
};

goog.object.extend(exports, proto.model);
